<template>
  <div class="not-found">
    <span class="no">404 NOT FOUND</span>
    <span class="text">Sorry, this page isn't available.</span>
    <span class="info">
      The link you followed may be broken, or the page may have been removed.
      <span
        class="link"
        @click="goToDashboard"
      >Go back to Home page.</span>
    </span>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "notfound",
  components: {},
  mounted() {},
  methods: {
    goToDashboard() {
      this.$router.push({ path: "login" });
    }
  }
};
</script>
<style lang="scss" scoped>
.not-found {
  width: 100%;
  height: auto;
  margin-top: -64px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .no {
    font-size: 20px;
    font-weight: bold;
    color: var(--primary-color);
  }
  .text {
    font-size: 30px;
    color: var(--text-color);
  }
  .info {
    font-weight: 100;
    color: var(--text-color);
    font-style: italic;
  }
  .link {
    font-weight: bold;

    color: rgb(74, 137, 255);
    cursor: pointer;
    &:hover {
      color: white;
    }
  }
}
</style>
